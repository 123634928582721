<template>
    <div class="app">
        <div class="wrap">
            <div class="wrapper">
                <AuthForm v-if="!isAuthorized"/>
                <template v-else>
                    <router-view v-if="isRouterAlive"/>
                </template>
            </div>
        </div>
    </div>
</template>

<script>

import '@/assets/scss/main.scss';

import AuthForm from "@/components/AuthForm.vue";
import Documents from "@/components/Documents.vue";

import {mapGetters, mapActions} from 'vuex';

export default {
    name: "App",
    provide() {
        return {
            reload: this.reload
        };
    },
    components: {
        AuthForm,
        Documents
    },
    data() {
        return {
            isRouterAlive: true
        };
    },

    async mounted() {
        //this.loading = true
        //await this.fetchStatuses()
        //await this.fetchPrescoringData(this.dealId)
        //await this.fetchDealData(this.dealId)
        //await this.createDeal(this.dealId);
        //this.loading = false
    },
    computed: {
        ...mapGetters([
            'getUser'
        ]),
        isAuthorized() {
            return this.getUser && this.getUser.access_token;
        }
        /*...mapGetters({
            prescoringData: 'getPrescoringData',
            dealData: 'getDealData',
            getRights: 'getRights',
            dealId: "getDealId",
            originalId: "getOriginalId",
        })*/
    },
    methods : {
        reload() {
            this.isRouterAlive = false;

            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        },
        /*async updateAll() {
            this.loading = true
            await this.fetchPrescoringData(this.dealId)
            await this.fetchDealData(this.dealId)
            await this.fetchDocuments(this.originalId || this.dealId)
            this.loading = false
        },

        ...mapActions({
            fetchStatuses: "fetchStatuses",
            fetchDocuments: "documents/fetchDocuments",
            fetchPrescoringData: 'fetchPrescoringData',
            fetchDealData: 'fetchDealData',
            createDeal: "documents/createDeal",
        }),*/
    }
};
</script>

<style lang="sass">


</style>
