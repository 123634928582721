<template>
    <div class="auth-form">
        <template v-if="isAuthorized">
            <div class="success-message info-message">Авторизация успешна.</div>
        </template>
        <template v-if="isError">
            <div class="error-message info-message">Ошибка авторизации.</div>
        </template>

        <div class="form-group">
            <label class="custom-label">Логин: <sup class="required">*</sup></label>
            <input v-model="user.login" class="custom-input" name="user-login" type="text">
        </div>
        <div class="form-group">
            <label class="custom-label">Пароль: <sup class="required">*</sup></label>
            <input v-model="user.password" class="custom-input" name="user-password" type="password">
        </div>
        <template v-if="!isAuthorized">
            <button class="auth-form__enter custom-btn" :class="!isReadyAuth ? 'disabled' : ''" @click="authorize">Войти
            </button>
        </template>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
    watch: {
        isAuthorized() {
            if(this.isAuthorized) {
                this.$router.push('documents')
            }
        }
    },
    computed: {
        ...mapGetters([
            'getUser'
        ]),
        isReadyAuth() {
            return this.user.login && this.user.password;
        },
        isAuthorized() {
            return this.getUser && this.getUser.access_token;
        },
        isError() {
            return this.getUser && this.getUser.error;
        }
    },
    methods : {
        ...mapActions([
            'sendAuth'
        ]),
        async authorize() {
            await this.sendAuth(this.user);
        }
    },
    data() {
        return {
            authData: {
                client_id    : 'externapi.alcor',
                client_secret: 'a09746a2-8806-4f97-c26e-e8f20c7fb4ec',
                grant_type   : 'password',
                scope        : 'extern.api',
                username     : 'testusera91c4adf1dd84c1f99aca88021299ab2',
                password     : 'testPassword'
            },
            user    : {
                login   : '',
                password: ''
            }
        };
    },
    mounted() {
        console.log(window.location);
    }
};

</script>

<style lang="scss">
.auth-form {
    margin    : 0 auto;
    max-width : 300px;

    &__enter {
        display : block;
        width   : 100%;
        margin  : 20px auto;
    }
}
</style>