<template>
    <div v-if="getDocuments['total-count'] > 0" class="documents-block" ref="refDocumentTable">

        <a-table class="document-table"
                 :columns="columns"
                 :data-source="arDocuments"
                 @change="onChangeTable"/>

<!--        <div v-for="(item, index ) in getDocuments['docflows-page-item']" class="document-item">
            <pre>{{ item }}</pre>
        </div>-->


    </div>


</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
    name    : 'Documents',
    watch   : {
        arDocuments() {
            this.pushLinkToTable();
            this.addClickEvent();
        }
    },
    computed: {
        ...mapGetters([
            'getUser',
            'getDocuments'
        ]),
        authToken() {
            return this.getUser && this.getUser.access_token;
        },
        arDocuments() {
            if (this.getDocuments && this.getDocuments['docflows-page-item']) {
                return this.getDocuments['docflows-page-item'].map((obDocument, index) => {
                    return {
                        number    : ++index,
                        id        : obDocument.id,
                        key       : index,
                        name      : this.getDocumentName(obDocument),
                        status    : this.getStatus(obDocument),
                        additional: this.getDate(obDocument['send-date'])
                    };
                });
            }
        }
    },
    created() {
        if (this.authToken) {
            this.getCompany(this.authToken);
        }

        console.warn('COOK')
        console.log(document.cookie);
    },
    methods: {
        ...mapActions([
            'getCompanyByInn'
        ]),
        async getCompany() {
            await this.getCompanyByInn({
                token: this.authToken,
                inn  : '8603136006-860301001'
            });
        },
        async onChangeTable() {
            await this.pushLinkToTable();
            await this.addClickEvent();
        },
        goToDetail(id) {
            this.$router.push('/documents/' + id);
        },
        addClickEvent() {
            this.$nextTick(() => {
                const antTable     = this.$refs.refDocumentTable.querySelector('.document-table'),
                      antTableBody = antTable.querySelector('.ant-table-tbody'),
                      arButtons    = antTableBody.querySelectorAll('button');

                Array.from(arButtons).map(item => {
                    const documentId = item.getAttribute('data-id');

                    item.addEventListener('click', event => {
                        this.goToDetail(documentId);
                    });
                });
            });
        },
        pushLinkToTable() {
            this.$nextTick(() => {
                if (this.arDocuments && this.$refs.refDocumentTable) {
                    const antTable     = this.$refs.refDocumentTable.querySelector('.document-table'),
                          antTableBody = antTable.querySelector('.ant-table-tbody');

                    this.arDocuments.map((obDocument, index) => {
                        const targetRow = antTableBody.querySelector('tr[data-row-key="' + (++index) + '"]');

                        if (targetRow) {
                            const actionCell = document.createElement('td');
                            actionCell.innerHTML = `<button class="ant-btn ant-btn-primary"
                                                            data-id="${obDocument.id}">Подробно</button>`;

                            const targetActionCell = targetRow.querySelector('td:last-of-type');

                            targetRow.replaceChild(actionCell, targetActionCell);
                        }
                    });
                }
            });


        },
        formatDouble(minutes) {
            if (minutes < 10) {
                return minutes = '0' + minutes;
            } else {
                return minutes;
            }
        },
        getDate(strDate) {
            const targetDate = new Date(strDate);

            return targetDate.getDate() + ' ' + this.months[targetDate.getMonth()] + ' в ' + targetDate.getHours() + ':' + this.formatDouble(targetDate.getMinutes());
        },
        getDocumentName(item) {
            if (item['description']['form-versions']) {
                const lastItem = item['description']['form-versions'].length - 1;

                return item['description']['form-versions'][lastItem]['form-shortname'];
            } else if (item['description']['form-version']) {
                return item['description']['form-version']['form-shortname'];
            } else if (item['description']['subject']) {
                return item['description']['subject'];
            }
        },
        getStatus(obDocument) {
            const strDocumentName = this.getDocumentName(obDocument);
            if (!strDocumentName.includes('Требование')) {
                if (obDocument['success-state'] === 'urn:docflow-state:failed') {
                    return 'Не принят';
                } else if (obDocument['success-state'] === 'urn:docflow-state:neutral') {
                    return 'Получено';
                } else {
                    return 'Принят';
                }
            }
        }
    },
    data() {
        return {
            columns: [
                {
                    dataIndex: 'number',
                    key      : 'number',
                    title    : '№'
                },
                {
                    dataIndex: 'name',
                    key      : 'name',
                    title    : 'Название'
                },
                {
                    title    : 'Дополнительная информация',
                    key      : 'additional',
                    dataIndex: 'additional'
                },
                {
                    title    : 'Статус',
                    key      : 'status',
                    dataIndex: 'status'
                },
                {
                    key: 'action'
                }
            ],
            months : [
                'января',
                'февраля',
                'марта',
                'апреля',
                'мая',
                'июня',
                'июля',
                'августа',
                'сентября',
                'октября',
                'ноября',
                'декабря'
            ]
        };
    }
};
</script>

<style lang="scss">
.documents-block {
    max-width : 880px;
    margin    : 0 auto;
}

.document-item {
    display         : flex;
    justify-content : space-between;
    padding         : 20px 0;

    &__name {
        margin-right : 30px;
        line-height  : 21px;
    }

    &__date {
        flex-shrink : 0;
        width       : 220px;

        &-text {
            margin-bottom : 5px;
        }
    }

    &:not(:last-of-type) {
        border-bottom : 1px solid #e6e6e6;
    }
}

</style>