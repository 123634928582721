<template>
    <div v-if="getDocument['id']" class="detail-document">
        <button class="ant-btn ant-btn-primary" @click="goBack">Назад</button>&nbsp;&nbsp;
        <button class="ant-btn ant-btn-primary" @click="onReload">Перезагрузить</button>
<!--        <button @click="goHome">Домой</button>-->
        <br><br>
        <h3>{{ getDocumentName(getDocument) }}</h3>
        <span>{{ getDate(getDocument['send-date']) }}</span>
        <p>из ФНС {{ getDocument['description']['cu'] }}</p>
        <pre></pre>

        <span>Отправитель: {{ getDocument['description']['cu'] }}</span><br>
        <span>Получатель: {{ getDocument['documents'][0].signatures[0].title }} - {{ getDocument['description']['recipient-inn'] }}</span><br>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
    name : 'DocumentDetail',
    props: ['documentId'],
    inject: ['reload'],
    computed: {
        ...mapGetters([
            'getUser',
            'getDocument'
        ]),
        authToken() {
            return this.getUser && this.getUser.access_token;
        }
    },
    data() {
        return {
            months : [
                'января',
                'февраля',
                'марта',
                'апреля',
                'мая',
                'июня',
                'июля',
                'августа',
                'сентября',
                'октября',
                'ноября',
                'декабря'
            ]
        }
    },
    methods: {
        ...mapActions([
            'getDocumentById'
        ]),
        goBack() {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
        },
        onReload() {
            this.reload();
        },
        goHome() {
            window.location.href = "/";
        },
        async getDetailDocument() {
            await this.getDocumentById({
                token: this.authToken,
                docflowId: this.documentId,
            });
        },
        getDocumentName(item) {
            if (item['description']['form-versions']) {
                const lastItem = item['description']['form-versions'].length - 1;

                return item['description']['form-versions'][lastItem]['form-shortname'];
            } else if (item['description']['form-version']) {
                return item['description']['form-version']['form-shortname'];
            } else if (item['description']['subject']) {
                return item['description']['subject'];
            }
        },
        formatDouble(minutes) {
            if (minutes < 10) {
                return minutes = '0' + minutes;
            } else {
                return minutes;
            }
        },
        getDate(strDate) {
            const targetDate = new Date(strDate);

            return targetDate.getDate() + ' ' + this.months[targetDate.getMonth()] + ' в ' + targetDate.getHours() + ':' + this.formatDouble(targetDate.getMinutes());
        },
    },
    created() {
         this.getDetailDocument();
    },
    mounted() {
        console.warn('mounted');
    }
};
</script>