import { createWebHistory, createRouter } from "vue-router";

import Documents from '@/components/Documents.vue';
import DocumentDetail from '@/components/DocumentDetail.vue';

/*

{
  path: "/:catchAll(.*)",
  component: NotFound,
},
 */
const routes = [
	{
		path: '/documents',
		name: 'documents',
		component: Documents
	},
	{
		path: "/documents/:documentId",
		name: "documentDetail",
		component: DocumentDetail,
		props: true
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;