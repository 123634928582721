import {createStore} from "vuex";
//import documents from "./modules/documents"
import Fetch from "@/utils/fetch.js";

export default createStore({
	state    : {
		documents: {},
		document: {},
		user     : {}
	},
	mutations: {
		setDocuments(state, data) {
			state.documents = data;
		},
		setDocument(state, data) {
			state.document = data;
		},
		setUser(state, data) {
			state.user = data;
		}
	},
	actions  : {
		async getCompanyByInn(ctx, data) {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", "Bearer " + data.token);

			var requestOptions = {
				method  : 'GET',
				headers : myHeaders,
				redirect: 'follow'
			};

			const appCtx = ctx;

			await fetch("https://extern-api.testkontur.ru/v1/19c6aa01-f887-4529-a9a3-085e3540f14a/docflows?innKpp=" + data.inn, requestOptions)
				.then(response => response.text())
				.then(result => {
					//console.log(result);
					ctx.commit('setDocuments', JSON.parse(result));
				})
				.catch(error => console.log('error', error));
		},
		async getDocumentById(ctx, data) {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", "Bearer " + data.token);

			var requestOptions = {
				method  : 'GET',
				headers : myHeaders,
				redirect: 'follow'
			};

			const appCtx = ctx;

			await fetch("https://extern-api.testkontur.ru/v1/19c6aa01-f887-4529-a9a3-085e3540f14a/docflows/" + data.docflowId, requestOptions)
				.then(response => response.text())
				.then(result => {
					//console.log(result);
					ctx.commit('setDocument', JSON.parse(result));
				})
				.catch(error => console.log('error', error));
		},
		async sendAuth(ctx, user) {
			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

			var urlencoded = new URLSearchParams();
			urlencoded.append("client_id", "externapi.alcor");
			urlencoded.append("client_secret", "a09746a2-8806-4f97-c26e-e8f20c7fb4ec");
			urlencoded.append("grant_type", "password");
			urlencoded.append("scope", "extern.api");
			urlencoded.append("username", user.login);
			urlencoded.append("password", user.password);

			var requestOptions = {
				method  : 'POST',
				headers : myHeaders,
				body    : urlencoded,
				redirect: 'follow'
			};

			const appCtx = ctx;

			await fetch("https://identity.testkontur.ru/connect/token", requestOptions)
				.then(response => response.text())
				.then(result => {
					ctx.commit('setUser', JSON.parse(result));
				})

				.catch(error => console.log('error', error));

			/*// POST request using fetch with error handling
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					/!*'Authorization': 'Bearer my-token',
					'My-Custom-Header': 'foobar'*!/
				},
				body: JSON.stringify({
					client_id    : 'externapi.alcor',
					client_secret: 'a09746a2-8806-4f97-c26e-e8f20c7fb4ec',
					grant_type   : 'password',
					scope        : 'extern.api',
					username     : 'testusera91c4adf1dd84c1f99aca88021299ab2',
					password     : 'testPassword'
				})
			};
			await fetch('https://identity.testkontur.ru/connect/token', requestOptions)
				.then(async response => {
					const data = await response.json();

					// check for error response
					if (!response.ok) {
						// get error message from body or default to response status
						const error = (data && data.message) || response.status;
						return Promise.reject(error);
					}

					console.log(response.data)
					ctx.commit('setUser', response.data)
				})
				.catch(error => {
					this.errorMessage = error;
					console.error('There was an error!', error);
				});*/
		}
	},

	getters: {
		getDocuments(state) {
			return state.documents;
		},
		getDocument(state) {
			return state.document;
		},
		getUser(state) {
			return state.user;
		}
	}
	/*modules: {
		documents,
	},*/
});
