import {createApp} from "vue";

import {
	Divider,
	Table,
	Tag
} from 'ant-design-vue';

import '/src/assets/css/vendors/antd.min.css';

import App from "./App.vue";

//import axios from 'axios'
//Vue.prototype.axios = axios

import store from "./store";
import router from "./router";

import 'normalize.css/normalize.css';

createApp(App)
	.use(Divider)
	.use(Table)
	.use(Tag)
	.use(router)
	.use(store)
	.mount("#app");